@import 'src/styles/variables';

.not-found-page {
  display   : flex;
  flex-flow : column;
  flex-grow : 1;
  height    : 100%;
  padding   : 0 20px;

  &__container {
    display         : flex;
    flex-flow       : row;
    flex-grow       : 1;
    justify-content : center;
    min-height      : 43rem;

    @media only screen and (max-width : 550px) {flex-flow : column;}

    &__error {
      padding-bottom : 7px;
      font-size      : 10.2rem;
      font-weight    : 600;
      line-height    : 1;
      [data-theme=dark] & {color : $colorBackgroundTop;}
      [data-theme=light] & {color : #bebebe;}
      }

    &__content {
      max-width    : 50rem;
      margin-left  : 20px;
      padding-left : 20px;
      border-left  : 1px solid;
      [data-theme=dark] & {border-color : $colorBackgroundTop;}
      [data-theme=light] & {border-color : #bebebe;}

      @media only screen and (max-width : 550px) {
        margin-left  : 0;
        padding-top  : 10px;
        padding-left : 0;
        border       : none;
        }

      &__title, &__message {
        @media only screen and (max-width : 550px) {text-align : center;}
        }

      &__title {
        padding-bottom : 5px;
        font-size      : 2.4rem;
        font-weight    : 700;
        text-transform : uppercase;
        color          : $colorFontCommon;
        }

      &__message {
        font-size   : 1.6rem;
        font-weight : 700;
        color       : $colorFontLight;

        & > a {
          font-size   : 1.6rem;
          font-weight : 700;
          }
        }
      }
    }
  }